





























































































































































import { debounceProcess } from "@/helpers/debounce";
import useBlob from "@/hooks/useBlob";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DATE_TIMESTAMP,
  DEFAULT_DATE_FORMAT
} from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataResponseListInvoiceARForReportTax } from "@/models/interface/invoice.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { DataCalendar } from "@/models/interface/settings.interface";
import { contactServices } from "@/services/contact.service";
import { invoiceServices } from "@/services/invoice.service";
import { logisticServices } from "@/services/logistic.service";
import { settingsServices } from "@/services/settings.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingClose: boolean;
  loadingTaxMonth: boolean;
  loadingBranch: boolean;
  loadingCustomer: boolean;
  loadingInvoiceARNumber: boolean;
}

@Component
export default class ReportTaxInvoice extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "reportTaxInvoice" });
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading: LoadingComponent = {
    loadingClose: false,
    loadingTaxMonth: false,
    loadingBranch: false,
    loadingCustomer: false,
    loadingInvoiceARNumber: false,
  };
  dataTaxMonth: DataCalendar[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  dataCustomer: ContactData[] = [];
  dataInvoiceARNumber: DataResponseListInvoiceARForReportTax[] = [];

  formRules = {
    taxMonth: {
      label: "lbl_tax_month_year",
      name: "taxMonth",
      placeholder: "lbl_tax_month_year_placeholder",
      decorator: [
        "taxMonth",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: ["customerName"],
    },
    invoiceDate: {
      label: "lbl_invoice_date",
      name: "invoiceDate",
      placeholder: "lbl_invoice_date_placeholder",
      decorator: ["invoiceDate"],
    },
    invoiceARNumber: {
      label: "lbl_invoice_ar_no",
      name: "invoiceARNumber",
      placeholder: "lbl_invoice_ar_no_placeholder",
      decorator: ["invoiceARNumber"],
    },
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  handleReset() {
    this.form.resetFields();
  }

  handleDownloadCSV() {
    const { toDownload } = useBlob();

    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        branchId: res.branch,
        taxMonth: res.taxMonth,
        customerId: res.customerName,
        arNumber: res.invoiceARNumber,
        start:
          res.invoiceDate && res.invoiceDate.length === 2
            ? moment(res.invoiceDate[0]).format(DATE_FORMAT_YYYY_MM_DD)
            : "",
        end:
          res.invoiceDate && res.invoiceDate.length === 2
            ? moment(res.invoiceDate[1]).format(DATE_FORMAT_YYYY_MM_DD)
            : "",
      };
      this.loading.loadingClose = true;
      let prefixFilename = moment().format(DATE_TIMESTAMP);
      invoiceServices
        .printTaxInvoiceCSV(params)
        .then(response => {
          toDownload(response, "tax_invoice.csv");
        })
        .finally(() => (this.loading.loadingClose = false));
    });
  }

  handleDownloadXML() {
    const { toDownload } = useBlob();

    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        branchId: res.branch,
        taxMonth: res.taxMonth,
        customerId: res.customerName,
        arNumber: res.invoiceARNumber,
        start:
          res.invoiceDate && res.invoiceDate.length === 2
            ? moment(res.invoiceDate[0]).format(DATE_FORMAT_YYYY_MM_DD)
            : "",
        end:
          res.invoiceDate && res.invoiceDate.length === 2
            ? moment(res.invoiceDate[1]).format(DATE_FORMAT_YYYY_MM_DD)
            : "",
      };
      this.loading.loadingClose = true;
      let prefixFilename = moment().format(DATE_TIMESTAMP);
      invoiceServices
        .printTaxInvoiceXML(params)
        .then(response => {
          toDownload(response, "tax_invoice.xml");
        })
        .finally(() => (this.loading.loadingClose = false));
    });
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }

  getTaxMonth(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      modul: "PO",
    };
    if (valueSearch) params.search = `month~*${valueSearch}*`;
    this.loading.loadingTaxMonth = true;
    settingsServices
      .listOfCalendar(params, "")
      .then(response => {
        this.dataTaxMonth = response.data.filter(
          dataFilter => !dataFilter.month?.includes("Adj")
        );
      })
      .finally(() => (this.loading.loadingTaxMonth = false));
  }

  getCustomerName(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
    };
    if (valueSearch)
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loading.loadingCustomer = true;
    contactServices
      .listContactData(params)
      .then(response => (this.dataCustomer = response.data))
      .finally(() => (this.loading.loadingCustomer = false));
  }

  getInvoiceARNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.loading.loadingInvoiceARNumber = true;
    invoiceServices
      .getListInvoiceARForReportTax(params)
      .then(response => (this.dataInvoiceARNumber = response.data))
      .finally(() => (this.loading.loadingInvoiceARNumber = false));
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getTaxMonth = debounceProcess(this.getTaxMonth, 400);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getCustomerName = debounceProcess(this.getCustomerName, 400);
    this.getInvoiceARNumber = debounceProcess(this.getInvoiceARNumber, 400);
    this.getTaxMonth("");
    this.getBranch("");
    this.getCustomerName("");
    this.getInvoiceARNumber("");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
